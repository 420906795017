(function($) {
  $.fn.cssNumber = function(prop){
        var v = parseInt(this.css(prop),10);
        return isNaN(v) ? 0 : v;
  };
})(jQuery);

(function($){
	$.unserialize = function(serializedString){
		var str = decodeURI(serializedString);
		var pairs = str.split('&');
		var obj = {}, p, idx, val;
		for (var i=0, n=pairs.length; i < n; i++) {
			p = pairs[i].split('=');
			idx = p[0];

			if (idx.indexOf("[]") == (idx.length - 2)) {
				// Eh um vetor
				var ind = idx.substring(0, idx.length-2)
				if (obj[ind] === undefined) {
					obj[ind] = [];
				}
				obj[ind].push(p[1]);
			}
			else {
				obj[idx] = p[1];
			}
		}
		return obj;
	};
})(jQuery);
 
$(document).ready(function() { 
   
    var ie9 = false;
    if($(".ie9").length > 0){
        ie9 = true;
    }
    
    
    //Max length in ie9
    if(ie9){
      var txts = document.getElementsByTagName('textarea'); 

      for(var i = 0, l = txts.length; i < l; i++) {
            if(/^[0-9]+$/.test(txts[i].getAttribute("maxlength"))) { 
              var func = function() { 
                var len = parseInt(this.getAttribute("maxlength"), 10); 

                if(this.value.length > len) { 
                  
                  this.value = this.value.substr(0, len); 
                  return false; 
                } 
              }

              txts[i].onkeyup = func;
              txts[i].onblur = func;
            } 
        };
    }
    
    if(isMobile.android.device){
     
        var txts = $('input, textarea');     
        for(var i = 0, l = txts.length; i < l; i++) {
          
            if(/^[0-9]+$/.test(txts[i].getAttribute("maxlength"))) { 
                
              var func = function() { 
                var len = parseInt(this.getAttribute("maxlength"), 10); 
                
                if(this.value.length > len) { 
                  
                  this.value = this.value.substr(0, len); 
                  return false; 
                } 
              }

              txts[i].onkeyup = func;
              txts[i].onblur = func;
            } 
        };
    }
    
    if(isMobile.any){
        $(".btn-carica").text("SCATTA O CARICA UNA FOTO");  
        $(".btn-carica").addClass("btn-text-small");
    }
    
    
    if($(".sameHeight").length > 0){
        $(".sameHeight").matchHeight();
    }
        
    if($("body.form-error").length > 0){ 
        
        var bodyPaddingTop = $("body").cssNumber("padding-top");
        $('html, body').animate({
			scrollTop : $("#form-partecipa").offset().top - 140 - bodyPaddingTop
		}, 500);	
    }
    
    if ($('.squared-checkbox').length) {

        var $cb = $('.squared-checkbox');
        
        $.each($cb, function(index, value){

            if($(value).find('input').prop('checked')){
                $(value).addClass("selected");
            }
        });
        
        $cb.bind('click', function (e) {
            if ($(this).hasClass('selected')) {
                $(this).find('input').prop('checked', false);
                $(this).removeClass('selected');
            } else {
                $(this).find('input').prop('checked', true);
                $(this).addClass('selected');
            }
        });
    }
    $("body").on("click", "#player", function(){
        $("#player").addClass("visibile");
    });
    
    if ($('#count-chars').length) {
        $('#count-chars').text($("#text-excuse").val().length + "/140");
        $("#text-excuse").keyup(function(){
            $('#count-chars').text($("#text-excuse").val().length + "/140");
        })
    }
    if ($('#box-orderby').length) {
        orderBy.init();
    }
    if ($('#grid-gallery').length) {
        gallery.init();
    }
    
    if($(".slick-responsive").length){
        $('.slick-responsive').slick({
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 300,            
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplaySpeed: 5500,
            centerSlide: true,
            responsive: [{
                breakpoint: 768,
                settings: {
                    autoplay: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerSlide: true,
                }
            }]
        });
    }
    //SE c'è l'uploader della foto
    if($(".dropzone").length > 0){   
        
        
        var preview = $("#template");
        preview.hide();
        
        //Se nel form principale il campo hidden è popolato vuol dire che una foto era già pubblicata
        if($("#file-img-url").val() !== ""){
            $("#template").show();
            $("#dz-spinner").hide();
            $(".data-dz-thumbnail").attr("src", $("#file-img-url").val());
        }
        
        var myDropzone = Dropzone.options.dropzone = {
            paramName: "imgfile", // The name that will be used to transfer the file
            maxFilesize: 5, // MB
            acceptedFiles: ".jpg, .png, .jpeg",
            //thumbnailWidth: 250,
            //thumbnailHeight: 250,
            maxFiles:1,
            forceFallback: false,
            dictFileTooBig: "L’immagine caricata è troppo pesante",
            dictInvalidFileType: "Sono consentiti solo file .jpg o .png",
            previewTemplate: "",            
            previewsContainer: false,
            //previewsContainer: "#previews", // Define the container to display the previews          
            init:function() {
                this.on("addedfile", function(file) { 
                    $("#dz-error").empty();
                    if (this.files[1]!=null){
                        this.removeFile(this.files[0]);                        
                    }           
                    $(".data-dz-thumbnail").hide();
                    $(".data-dz-thumbnail").attr("src", "");
                    
                    $("#template").show();
                    $(".btn_delete").hide();
                    $("#dz-spinner").show();
                    $("#img-filename").val(this.files[0].name);
                });
                
                this.on("success", function(file, responseText) {
                    var data = JSON.parse(responseText);
                    
                    if(data.imagename){
                       
                       $(".data-dz-thumbnail").attr("src", data.imagename); 
                       $(".data-dz-thumbnail").show();
                       $(".btn_delete").show();
                       $("#dz-spinner").hide();
                    }else if(data.status === false && data.error){                        
                        $("#dz-error").text(data.error);
                        this.removeFile(this.files[0]);  
                        $(".data-dz-thumbnail").attr("src", "");                 
                        $(".data-dz-thumbnail").hide();
                        $("#img-filename").val("");
                        $("#dz-spinner").hide();
                    }                    
                });
                
                this.on("error", function(file, errorMessage) {                    
                    this.removeFile(this.files[0]);                                            
                    $("#img-filename").val("");
                    $("#template").hide();
                    $(".data-dz-thumbnail").hide();
                    $(".data-dz-thumbnail").attr("src", "");                 
                    $("#dz-error").text(errorMessage);
                    $("#dz-spinner").hide();
                });
                
                this.on("reset", function() {                     
                    $("#img-filename").val("");
                });
            }
        };    
        
        //
        $( "body" ).on('change','#regUploadLoc', 
            function(){ 
                var filename = $("#regUploadLoc").val();
                if (filename.substring(3,11) == 'fakepath' ){ 
                    filename = filename.substring(12); 
                }
                // remove c:\fake at beginning from localhost chrome
                $("#img-filename").val(filename);
            }
        );
        
        $("body").on("click", ".dz-browser-not-supported .btn-carica", function(){ 
            var options = {         
                beforeSubmit:  showRequest,  // pre-submit callback 
                success:       showResponse
            }
            
            $("#dropzone").ajaxSubmit(options);  
            
        });
        
        $("body").on("click", ".btn_delete", function(){ 
            $("#regUploadLoc").val("");
            $("#img-filename").val("");
            $(".data-dz-thumbnail").attr("src", "");                 
            $("#template").hide();
        });

        // pre-submit callback 
        function showRequest(formData, jqForm, options) {            
            
            var filename = $("#regUploadLoc").val();
            if(filename == ""){
                $("#dz-error").text("Non è stato selezionato nessun file da inviare");
                return false;
            }else{
                
                $("#template").show();  
                $(".btn_delete").hide();
                $("#dz-spinner").show();
                
                $("#regUploadLoc").val("");
                return true;     
            }
            
        } 

        // post-submit callback 
        function showResponse(responseText, statusText, xhr, $form)  { 
            
            var data = JSON.parse(responseText);                  
            if(data.imagename){                
                $("#dz-error").empty();                
                $(".btn_delete").show();
                $("#dz-spinner").hide();                
                $(".data-dz-thumbnail").attr("src", data.imagename);                 
                $(".data-dz-thumbnail").show();
            }else if(data.status === false && data.error){   
                $("#template").hide();
                $(".data-dz-thumbnail").attr("src", "");     
                $(".data-dz-thumbnail").hide();                
                $("#dz-error").text(data.error);
                this.removeFile(this.files[0]);                                            
                $("#img-filename").val("");
            }  
        }      
    }
        
    if ($('#form-partecipa').length) {
        //form_partecipa.init();
        form_partecipa.ie9Fix();
        
        $(".btn-partecipa").click(function(){                        
            $("#excuse-content").val($("#text-excuse").val());
            $("#file-img-url").val($(".data-dz-thumbnail").attr("src"));                          
            $("#checkbox-regolamento").val($("input[name=checkregolamento]").is(':checked'));
            $("#checkbox-promo").val($("input[name=checkpromo]").is(':checked'));  
            $("#recaptcha").val(grecaptcha.getResponse());  
            
                
        
            $('#form-partecipa').submit();
        });
    }
    
    //MENU MOBILE
   
    $('.menu-mobile').sidr({
        displace:false,
        onOpenEnd:function(){            
            $("#open-menu").addClass("hide");
            $("#close-menu").removeClass("hide");
        },
        onCloseEnd:function(){
            $("#open-menu").removeClass("hide");
            $("#close-menu").addClass("hide");
        }
    }); 
});


var form_partecipa = {
    cansend: false,
    $theform: null,
    init: function () {
        var $this = this;
        $this.$theform = $('#form-partecipa');

        $this.$theform.bind('submit', function (e) {
            form_partecipa.checkForm();
            return false;
        });
    },

    ie9Fix:function()
    {
        this.$theform = $('#form-partecipa');

        function isIE () {
            var myNav = navigator.userAgent.toLowerCase();
            return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
        }
        var _versionIe=isIE();


        if (_versionIe<10) {
            if (!("placeholder" in document.createElement("input"))) {
                $("input[placeholder], textarea[placeholder]").each(function () {
                    var val = $(this).attr("placeholder");
                    if (this.value == "") {
                        this.value = val;
                    }
                    $(this).focus(function () {
                        if (this.value == val) {
                            this.value = "";
                        }
                    }).blur(function () {
                        if ($.trim(this.value) == "") {
                            this.value = val;
                        }
                    })
                });
                $('form').submit(function () {
                    $(this).find("input[placeholder], textarea[placeholder]").each(function () {
                        if (this.value == $(this).attr("placeholder")) {
                            this.value = "";
                        }
                    });
                });
            }
        }
    },

    checkForm: function () {
        var $this = this;
        var TX = false;
        var CB = false;
        var SEL = false;
        var type = '';
        var val = '';
        var validateType = '';
        var $required = $this.$theform.find('*[data-validate="required"]');

        $required.each(function () {
                var $erroMsg = null;

                type = $(this).getType();
                val = $(this).val();
                validateType = $(this).attr('data-validate-type');

                if (type == 'text' || type == 'password') {

                    if (val === '') {
                        $erroMsg = $(this).parent().parent().addClass('error').find('.error-msg');
                        $erroMsg.html('Campo obbligatorio');
                        TX = false;
                    }
                    else if (val !== '') {
                        switch (validateType) {
                            case 'cap':
                                if (val.length !== 5 || isNaN(val)) {
                                    $erroMsg = $(this).parent().parent().addClass('error').find('.error-msg');
                                    $erroMsg.html('Verifica CAP');
                                    TX = false;
                                }
                                break;
                            case 'email':
                                if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val)) {
                                    $erroMsg = $(this).parent().parent().addClass('error').find('.error-msg');
                                    $erroMsg.html('Verifica email');
                                    TX = false;
                                }
                                break;
                            case 'same':
                                if (val !== $('input[name="password"]').val()) {
                                    $erroMsg = $(this).parent().parent().addClass('error').find('.error-msg');
                                    $erroMsg.html('Password non coincide');
                                    TX = false;
                                }
                                break;
                        }
                    } else {
                        $erroMsg = $(this).parent().parent().removeClass('error').find('.error-msg');
                        $erroMsg.html('&nbsp;');
                        TX = true;
                    }
                }

                if (type == 'checkbox') {
                    if ($(this).prop('checked')) {
                        $erroMsg = $(this).parent().parent().addClass('error').find('.error-msg');
                        $erroMsg.html('&nbsp;');
                        CB = true;
                        //$this.cansend = true;
                    } else {
                        $erroMsg = $(this).parent().parent().removeClass('error').find('.error-msg');
                        $erroMsg.html('Campo obbligatorio');
                        CB = false;
                        //$this.cansend = false;
                    }
                }
                else if (type == 'select') {
                    if ($(this).val() == 'none') {
                        $erroMsg = $(this).parent().parent().find('.error-msg');
                        $(this).addClass('error');
                        $erroMsg.html('Campo obbligatorio');
                        SEL = false;
                        //$this.cansend = false;
                    } else {
                        $erroMsg = $(this).parent().parent().find('.error-msg');
                        $(this).removeClass('error');
                        $erroMsg.html('&nbsp;');
                        SEL = true;
                        // $this.cansend = true;
                    }
                }
                else {
                    $erroMsg = $(this).parent().parent().removeClass('error').find('.error-msg');
                    $erroMsg.html('&nbsp;');
                }
            }
        );

        if (TX && CB && SEL) {
            $this.cansend = true;
        }

    }
}

var gallery = {
    $grid: null,
    $pics: null,
    $picDetails : null,
    init: function () {
        var $this = this;

        $this.$grid = $('#grid-gallery');
        if (!isMobile.phone) {
            
            $('#grid-gallery').masonry({
                // options
                itemSelector: '.picture-item',
                columnWidth: 1
            });

            $this.$grid.imagesLoaded(function () {
                $this.$grid.masonry('layout');
                $(".picture-item").addClass("loaded");
            });
        }
        else{
            setTimeout(function(){$(".picture-item").addClass("loaded");},1000);
        }

            $this.$pics = $this.$grid.find('.picture-item');
            $this.$picDetails = $this.$pics.find('.picture-details');

            $this.$pics.find('.ico-vote>a').bind('click', function (e) {
                e.preventDefault();
                if ($(this).parent().attr('data-is-voted') == 'false') {
                    var picId = $(this).parent().attr('data-pic-id');
                    gallery.setVote(picId);
                }
            });


        if (!isMobile.any) {
            $this.$pics.bind('mouseenter', function (e) {
                $(this).find('.picture-details').show();
            });
            $this.$pics.bind('mouseleave', function (e) {
                $(this).find('.picture-details').hide();
            });

        }else if(isMobile.tablet){
            $this.$pics.bind('click', function (e) {


             if($(this).find('.picture-details').is(':visible')){
                    $this.$picDetails.hide();
                }else{
                 $this.$picDetails.hide();
                 $(this).find('.picture-details').show();
                }

            });
        }
    },
}

var orderBy = {
    init: function () {

        if(isMobile.any){
            $('#box-orderby').addClass('touch');
            $('#box-searchby').addClass('touch');
        }

        var $btns = $('#box-orderby').find('ul');

        if (!isMobile.any) {
            $btns.bind('mouseenter', function (e) {
                $(this).addClass('over');
            });
            $btns.bind('mouseleave', function (e) {
                $(this).removeClass('over');
            });
        } else {
            $btns.bind('click', function (e) {
                //e.preventDefault();
                if($(this).hasClass('over')){
                    $(this).removeClass('over');
                }else{
                    $(this).addClass('over');
                }
            });

            var _li=$btns.find("li");
            _li.bind("click",function(e){
                if($(this).hasClass("selected"))
                {
                    e.preventDefault();
                }
            });
        }

    }
}